@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300&family=Odibee+Sans&display=swap');

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body
{
    overflow: hidden;
    font-family: 'Akshar', sans-serif;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.header
{
    width: 100%;
    display: block;
    text-align: center;
    position: absolute;
    top: 44px;
}

.test
{
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

h1, p, h2, ul, h3
{
    font-size: 30px;
    color: #ffffff;
    mix-blend-mode: difference;
}

a:link, a:visited, a:hover, a:active { 
    text-decoration: none; 
}

a
{
    color: inherit;
}


.header h1
{
    z-index: 999999999999999999;
    font-size: 16px;
}



.test h2 {
    font-size: 36px;
    color: rgb(208, 67, 67);
}

.test p
{
    font-size: 60px;
}

.test ul
{
    font-size: 24px;
}

.test h3
{
    font-size: 40px;
}

.services
{
    position: fixed;
    bottom: 140px;
    left: 20px;
    text-decoration: none;
}

ul
{
    list-style: none;
}

button
{
    cursor: pointer;
    font-size: 20px;
    font-family: 'Akshar', sans-serif;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.377);
    border: none;
    border-radius: 32px;
    color: white;
    z-index: 9999999999;
    transition: all ease 0.5s;
}

button:hover
{
    opacity: 0.8;
}

.open-gallery button
{
    position: fixed;
    right: 20px;
    bottom: 140px;
} 

/********* IMAGE GALLERY *********/
.image-gallery {
    max-width: 100%;
    width: 500px;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    background: #000000da;
    opacity: 0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transition: opacity ease-in 0.5s;
    visibility: hidden;
}
  
  .image-gallery:hover .controls {
    opacity: 1;
  }
  
  .controls {
    opacity: 0;
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    justify-content: space-between;
    width: 100%;
    z-index: 99999;
    transition: all ease 0.5s;
    font-family: Arial, Helvetica, sans-serif;
   }
    .control {
      margin: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      opacity: .5;
      transition: ease .3s;
      cursor: pointer;
    }  
      .control:hover {
        opacity: 1;
      }
    
  
  
    .image-gallery-slides {
      position: absolute;
      top: 45%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      width: 100%;
      transition: 1s ease-in-out all;
      pointer-events: none;
    }  
    .image-gallery-slide {
        min-width: 100%;
        min-height: 40vh;
        height: auto;
    }
    
#close button {
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    top: 20px;
    left: 20px;
    color: white;
}






@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-200px * 19))}
}

.slider {
	height: 80px;
	margin: auto;
	overflow: hidden;
	position: fixed;
	width: 100%;
    bottom: 24px;
    pointer-events: none;
    background-color: #1c1c1c;
}

.slide-track {
		animation: scroll 44s linear infinite;
		display: flex;
		width: calc(200px * 19);
        background-color: #1c1c1c;
	}

.slide {
		height: 80px;
		width: 200px;
	}


.footer-wrapper {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: right; */
    text-align: center;
    font-size: 12px;
    color: white;
}

footer {
    margin-left: 20px;
}

footer a {
    font-family: 'Odibee Sans', cursive;
    font-size: 16px;
    color: white;
}

@media only screen and (max-width: 768px) {
    .header h1, .services ul li, .gallery-link p
    {
        font-size: 16px;
        color: white;
    }

  
}

@media only screen and (max-width: 525px) {

    .menu-content
    {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #00000083;
        z-index: 99;
        /* color: white; */
        width: 100vw;
        height: 100vh;
        visibility: hidden;
        transition: opacity ease-in 0.5s;
        opacity: 0;
    }

    #menu-button {
        position: absolute;
        top: 20px;
        left: 20px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }

    #back button {
        position: absolute;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        top: 20px;
        left: 20px;
        color: white;
    }

    .header {
        z-index: 99999;
    }
}